.interfaces-table {
  td[data-name="name"] {
    width: 100%;
  }

  td[data-name="major"],
  td[data-name="minor"] {
    min-width: 7em;
  }
}
