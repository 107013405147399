$primary: #0631cc;
$black: #000;

$box-shadow: 0 0.2rem 0.75rem rgba($black, 0.2);
$box-shadow-sm: 0 0.1rem 0.3rem rgba($black, 0.15);
$box-shadow-lg: 0 0.25rem 2rem rgba($black, 0.2);
$box-shadow-inset: inset 0 0 2px rgba($black, 0.075);

$border-radius: 0.5rem;
$border-radius-sm: 0.3rem;
$border-radius-lg: 0.8rem;

@import "~bootstrap/scss/bootstrap.scss";

:root {
  font-size: 0.9rem;
  --cp-color-accent: #11b0ef;
}
