.Topbar {
  height: 5em;

  .LanguageMenu {
    &-options {
      max-height: 80vh;
      overflow-y: auto;
    }
  }
}
